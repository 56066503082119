<template>
  <section class="m-4">
    <b-row class="mb-1">
      <b-col
        md="3"
        offset-md="9"
      >
        <div class="d-flex justify-content-end">
          <!-- Button: Print -->
          <b-button
            variant="relief-success"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-card
      id="printMe"
      no-body
      class="invoice-preview-card"
    >
      <!-- Header -->
      <b-card-body class="invoice-padding pb-0">
        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
          <!-- Header: Left Content -->
          <div>
            <div class="logo-wrapper">
              <h1 class="font-weight-bolder">
                UMORE GAME <br>
                PROVIDER
              </h1>
            </div>
          </div>
        </div>
      </b-card-body>

      <!-- Spacer -->
      <hr class="invoice-spacing">

      <!-- Invoice Client & Payment Details -->
      <b-card-body
        class="invoice-padding pt-0"
      >
        <b-row class="invoice-spacing">
          <!-- Col: Invoice To -->
          <b-col
            cols="12"
          >
            <h4 class="font-weight-bolder">
              INVOICE : {{ invoiceDetail.ref || '' }}
            </h4>

            <b-row class="mb-1">
              <b-col cols="2">
                DUE DATE:
              </b-col>
              <b-col>
                {{ invoiceDetail.dueDate | date }}
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col cols="2">
                BILLED TO:
              </b-col>
              <b-col>
                {{ invoiceDetail.agentName || '' }}
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="2">
                BILLING CYCLE:
              </b-col>
              <b-col>
                {{ invoiceDetail.startDate | date }}
                -
                {{ invoiceDetail.endDate | date }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Invoice Description: Table -->
      <div style="min-height: 40vh;">
        <b-table-simple
          responsive
        >
          <b-thead>
            <b-tr>
              <b-th>
                AGENTS
              </b-th>
              <b-th class="text-right">
                TOTAL FEES
              </b-th>
              <b-th class="text-right">
                % COMMISSION RATE
              </b-th>
              <b-th class="text-right">
                TOTAL
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="data in invoiceTable"
              :key="data.id"
            >
              <b-th>
                {{ data.agentName }}
              </b-th>
              <b-th class="text-right">
                ฿{{ data.totalFee | currency }}
              </b-th>
              <b-th class="text-right">
                {{ data.commissionRate }}%
              </b-th>
              <b-th class="text-right">
                ฿{{ data.outstanding | currency }}
              </b-th>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td
                colspan="3"
                class="text-right"
              >
                <strong>TOTAL AMOUNT : </strong>
              </b-td>
              <b-td
                class="text-right"
              >
                ฿{{ invoiceDetail.totalAmount | currency }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <!-- Spacer -->
      <hr class="invoice-spacing">

      <!-- Note -->
      <b-card-body class="invoice-padding text-right">
        <h2>
          Thank you<br> very much!
        </h2>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PrintInvoice',
  data() {
    return {

      fields: [
         {
          key: 'agentName',
          label: 'AGENTS',
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'totalFee',
          label: 'TOTAL FEES',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'commissionRate',
          label: '% COMMISSION RATE',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'outstanding',
          label: 'TOTAL',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['invoice', 'invoices']),
    invoiceId() {
      return this.$route.params.id
    },
    invoiceDetail() {
      if(this.invoices.length > 0) {
        return this.invoices.find((invoice) => invoice.id === this.invoiceId)
      }
      return this.invoice
    },
    invoiceTable() {
      return [
        {
          id: this.invoiceDetail.id || this.invoiceId,
          agentName: this.invoiceDetail.agentName || '' ,
          totalFee: this.invoiceDetail.totalFee || 0,
          commissionRate: this.invoiceDetail.commissionRate || '',
          outstanding: this.invoiceDetail.outstanding || 0,
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getInvoices']),
    fetchData() {
      if(!this.invoice.id){
        this.getInvoices({
          limit: 20,
          skip: '',
          search: '',
          status: '',
        });
      }
    },
    async printInvoice() {
      await this.$htmlToPaper('printMe');
    },
  },
}
</script>
